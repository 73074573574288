import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default function Projects() {
  const projects = [
    '/Image/Image -Panel.jpeg',
    '/Image/Inverter - 1.jpeg',
    '/Image/Inverter -2.jpeg',
    '/Image/Inverter.jpeg',
    '/Image/Panel -2.jpeg',
    '/Image/Panel -3.jpeg',
    '/Image/Solar Heater.jpeg'
  ];

  return (
    <section id="projects" className="py-16 sm:py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
            Our Recent Installations
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            Take a look at some of our successful solar installations across Tamil Nadu. Each project represents our commitment to quality and customer satisfaction.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Swiper
            modules={[Autoplay, Pagination]}
            spaceBetween={20}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            className="pb-12"
          >
            {projects.map((image, index) => (
              <SwiperSlide key={index}>
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  className="aspect-[4/3] rounded-lg overflow-hidden shadow-lg"
                >
                  <img
                    src={image}
                    alt={`Solar Installation Project ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        </motion.div>
      </div>
    </section>
  );
}