import { motion } from 'framer-motion';
import { Zap, Shield, Clock, Info } from 'lucide-react';

const solarSystems = [
  { 
    capacity: '1.0 KW',
    actualCapacity: '1.1 KWp',
    price: 80000,
    subsidy: 30000,
    generation: 165
  },
  { 
    capacity: '2.0 KW',
    actualCapacity: '2.2 KWp',
    price: 140000,
    subsidy: 60000,
    generation: 330
  },
  { 
    capacity: '3.0 KW',
    actualCapacity: '3.3 KWp',
    price: 195000,
    subsidy: 78000,
    generation: 495
  },
  { 
    capacity: '5.0 KW',
    actualCapacity: '4.95 KWp',
    price: 295000,
    subsidy: 78000,
    generation: 750
  }
];

export default function Pricing() {
  return (
    <section id="pricing" className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid gap-8 lg:grid-cols-2 mb-12">
          {/* Monthly Generation - Left Side */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="h-full"
          >
            <div className="bg-white rounded-xl shadow-lg p-6 h-full flex flex-col">
              <div className="flex items-center gap-2 mb-4">
                <Zap className="h-5 w-5 text-yellow-500" />
                <h3 className="text-xl font-semibold">Monthly Generation</h3>
              </div>
              <div className="overflow-x-auto flex-grow">
                <table className="w-full h-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">System Size</th>
                      <th className="px-4 py-2 text-right text-sm font-semibold text-gray-900">Units Generated</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {solarSystems.map((system) => (
                      <tr key={system.capacity}>
                        <td className="px-4 py-4 text-sm text-gray-900">{system.capacity}</td>
                        <td className="px-4 py-4 text-sm text-right text-gray-900">{system.generation} Units</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </motion.div>

          {/* Right Side Content */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            {/* Warranty Information */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex items-center gap-2 mb-4">
                <Shield className="h-5 w-5 text-yellow-500" />
                <h3 className="text-xl font-semibold">Warranty & Service</h3>
              </div>
              <div className="space-y-3">
                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4 text-gray-400" />
                  <span className="text-gray-600">25 Years Product Warranty</span>
                </div>
                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4 text-gray-400" />
                  <span className="text-gray-600">5 Years Free Service</span>
                </div>
              </div>
            </div>

            {/* TANGEDCO Charges */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex items-center gap-2 mb-4">
                <Info className="h-5 w-5 text-yellow-500" />
                <h3 className="text-xl font-semibold">TANGEDCO Net Meter Charges</h3>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">3 Phase Service Connection</span>
                  <span className="font-medium">₹10,541/-</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">1 Phase Service Connection</span>
                  <span className="font-medium">₹4,537/-</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Networking Charges</span>
                  <span className="font-medium">0.37 Paise per Unit</span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Notes */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center space-y-2 text-sm text-gray-500"
        >
          <p>* Subsidy amount will be credited directly to your Aadhar-linked bank account</p>
          <p>* Contact us for detailed information and site inspection</p>
        </motion.div>
      </div>
    </section>
  );
}
