import { motion } from 'framer-motion';
import { Sun, Award, Users, Clock, Shield, ThumbsUp } from 'lucide-react';

export default function About() {
  const features = [
    {
      icon: <Award className="h-6 w-6 text-primary-500" />,
      title: "Decade of Experience",
      description: "Trusted expertise in solar solutions"
    },
    {
      icon: <Users className="h-6 w-6 text-primary-500" />,
      title: "Dedicated Team",
      description: "Skilled professionals at your service"
    },
    {
      icon: <Shield className="h-6 w-6 text-primary-500" />,
      title: "Quality Assurance",
      description: "Reliable products with warranty support"
    },
    {
      icon: <Clock className="h-6 w-6 text-primary-500" />,
      title: "Quick Service",
      description: "Efficient installation and support"
    },
    {
      icon: <Sun className="h-6 w-6 text-primary-500" />,
      title: "Solar Solutions",
      description: "Comprehensive solar services"
    },
    {
      icon: <ThumbsUp className="h-6 w-6 text-primary-500" />,
      title: "Customer Focus",
      description: "Dedicated to customer satisfaction"
    }
  ];

  return (
    <section id="about" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <span className="text-primary-500 font-semibold">About Us</span>
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
            Your Trusted Solar Energy Partner
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            With a decade of experience in solar installations, we are committed to providing sustainable energy solutions across Tamil Nadu. Our expertise spans residential, commercial, and industrial solar systems.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="bg-primary-50 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="bg-primary-600 text-white rounded-2xl p-8 md:p-12 relative overflow-hidden"
        >
          <div className="absolute inset-0 bg-[url('/solar-pattern.png')] opacity-10" />
          <div className="relative z-10">
            <h3 className="text-2xl md:text-3xl font-bold mb-4">
              Why Choose Adithya Solar?
            </h3>
            <p className="text-lg mb-6 text-primary-100">
              As a leading solar solutions provider in Tamil Nadu, we deliver excellence through our commitment to quality, 
              reliability, and customer satisfaction.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-primary-50">
              <div className="flex flex-col items-center text-center space-y-2">
                <div className="bg-primary-500/20 p-3 rounded-full">
                  <Shield className="h-6 w-6" />
                </div>
                <h4 className="font-semibold">Quality Assurance</h4>
                <p className="text-sm">Premium Products with Extended Warranty</p>
              </div>
              <div className="flex flex-col items-center text-center space-y-2">
                <div className="bg-primary-500/20 p-3 rounded-full">
                  <Users className="h-6 w-6" />
                </div>
                <h4 className="font-semibold">Expert Team</h4>
                <p className="text-sm">Professional Installation & Support</p>
              </div>
              <div className="flex flex-col items-center text-center space-y-2">
                <div className="bg-primary-500/20 p-3 rounded-full">
                  <Award className="h-6 w-6" />
                </div>
                <h4 className="font-semibold">Customer Success</h4>
                <p className="text-sm">99% Satisfaction Rate</p>
              </div>
              <div className="flex flex-col items-center text-center space-y-2">
                <div className="bg-primary-500/20 p-3 rounded-full">
                  <Clock className="h-6 w-6" />
                </div>
                <h4 className="font-semibold">Quick Service</h4>
                <p className="text-sm">Fast Installation & Response Time</p>
              </div>
              <div className="flex flex-col items-center text-center space-y-2">
                <div className="bg-primary-500/20 p-3 rounded-full">
                  <Sun className="h-6 w-6" />
                </div>
                <h4 className="font-semibold">Subsidy Support</h4>
                <p className="text-sm">Government Scheme Assistance</p>
              </div>
              <div className="flex flex-col items-center text-center space-y-2">
                <div className="bg-primary-500/20 p-3 rounded-full">
                  <ThumbsUp className="h-6 w-6" />
                </div>
                <h4 className="font-semibold">Maintenance</h4>
                <p className="text-sm">Regular Service & Support</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}