import { motion } from 'framer-motion';
import { Phone, MessageCircle } from 'lucide-react';

export default function FloatingActions() {
  const whatsappNumber = "918838240016";
  const phoneNumber = "918838240016";

  return (
    <div className="fixed bottom-8 right-8 flex flex-col space-y-4 z-50">
      <motion.a
        href={`tel:+${phoneNumber}`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="bg-primary-500 text-white p-4 rounded-full shadow-lg hover:bg-primary-600 transition-colors"
        aria-label="Call us"
      >
        <Phone className="h-6 w-6" />
      </motion.a>
      
      <motion.a
        href={`https://wa.me/${whatsappNumber}?text=Hi,%20I'm%20interested%20in%20solar%20installation`}
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors"
        aria-label="Chat on WhatsApp"
      >
        <MessageCircle className="h-6 w-6" />
      </motion.a>
    </div>
  );
}