import { motion } from 'framer-motion';
import { ClipboardCheck, Settings, Truck, Zap } from 'lucide-react';

export default function Process() {
  const steps = [
    {
      icon: <ClipboardCheck className="h-8 w-8" />,
      title: "Consultation",
      description: "Free site assessment and customized solar solution proposal"
    },
    {
      icon: <Settings className="h-8 w-8" />,
      title: "Design",
      description: "Professional system design optimized for your property"
    },
    {
      icon: <Truck className="h-8 w-8" />,
      title: "Installation",
      description: "Expert installation by certified professionals"
    },
    {
      icon: <Zap className="h-8 w-8" />,
      title: "Activation",
      description: "System testing and grid connection activation"
    }
  ];

  return (
    <section id="process" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Process</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Simple, transparent, and efficient installation process
          </p>
        </motion.div>

        <div className="relative">
          {/* Process steps container */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="relative h-full"
              >
                {/* Step content */}
                <div className="bg-white p-6 rounded-lg shadow-lg text-center relative z-20 h-full flex flex-col justify-between">
                  <div>
                    <div className="inline-block p-3 bg-yellow-100 rounded-full mb-4">
                      {step.icon}
                    </div>
                    <h3 className="text-xl font-semibold mb-3">{step.title}</h3>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                </div>

                {/* Connection line - Only visible on large screens */}
                {index < steps.length - 1 && (
                  <div className="hidden lg:block absolute top-1/2 left-[calc(100%_-_2rem)] w-[calc(4rem_+_2vw)] h-0.5 bg-yellow-200 transform -translate-y-1/2 z-10" />
                )}

                {/* Step number */}
                <div className="absolute top-0 right-0 w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-white font-bold text-sm z-30 transform translate-x-2 -translate-y-2">
                  {index + 1}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}