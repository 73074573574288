import { Sun, Menu, X, MapPin, Phone } from 'lucide-react';
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const phoneNumber = '+91 88382 40016';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const navItems = ['Home', 'Services', 'About', 'Contact'];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <motion.nav
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        className={`fixed w-full z-50 transition-all duration-300 ${
          scrolled ? 'bg-white/95 shadow-md backdrop-blur-sm' : 'bg-transparent'
        } ${isMenuOpen ? 'bg-white' : ''}`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <motion.a
              href="#home"
              className="flex items-center space-x-2 z-50"
              whileHover={{ scale: 1.05 }}
            >
              <motion.div
                animate={{ 
                  rotate: 360,
                  scale: [1, 1.2, 1]
                }}
                transition={{ 
                  rotate: {
                    duration: 20,
                    repeat: Infinity,
                    ease: "linear"
                  },
                  scale: {
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }
                }}
                className="text-primary-500"
              >
                <Sun className="w-8 h-8" />
              </motion.div>
              <span className={`font-bold text-2xl ${
                scrolled || isMenuOpen ? 'text-gray-900' : 'text-white'
              }`}>
                Adithya Solar
              </span>
            </motion.a>
            
            <div className="hidden lg:flex items-center space-x-4 xl:space-x-8">
              {navItems.map((item) => (
                <motion.a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className={`${
                    scrolled ? 'text-gray-700' : 'text-white'
                  } hover:text-primary-500 transition-colors text-sm xl:text-base`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {item}
                </motion.a>
              ))}
              <span className={`${
                scrolled ? 'text-gray-700' : 'text-white'
              } text-sm xl:text-base hidden xl:inline-block`}>
                <a href={`tel:${phoneNumber}`} className="hover:text-primary-500 transition-colors">
                  +91 88382 40016
                </a>
              </span>
              <motion.a
                href={`https://wa.me/${phoneNumber.replace(/[^0-9]/g, '')}?text=Hi,%20I'm%20interested%20in%20solar%20installation`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-200"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Phone className="w-4 h-4 mr-2" />
                Get Quote
              </motion.a>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="lg:hidden z-50"
            >
              <motion.div
                animate={isMenuOpen ? { rotate: 90 } : { rotate: 0 }}
                transition={{ duration: 0.2 }}
              >
                {isMenuOpen ? (
                  <X className="w-6 h-6 text-gray-900" />
                ) : (
                  <Menu className={`w-6 h-6 ${scrolled ? 'text-gray-900' : 'text-white'}`} />
                )}
              </motion.div>
            </motion.button>
          </div>
        </div>
      </motion.nav>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="fixed top-16 inset-x-0 z-40 lg:hidden bg-white shadow-lg"
          >
            <div className="p-4 space-y-3 max-h-[calc(100vh-4rem)] overflow-y-auto">
              {navItems.map((item) => (
                <motion.a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className="block px-4 py-3 text-gray-900 hover:bg-primary-50 hover:text-primary-500 rounded-lg transition-all duration-300"
                  whileHover={{ x: 10, backgroundColor: "rgba(59, 130, 246, 0.1)" }}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item}
                </motion.a>
              ))}
              
              <motion.div
                className="block px-4 py-3 text-gray-900"
                whileHover={{ x: 10 }}
              >
                <div className="flex items-center gap-2">
                  <MapPin className="w-4 h-4 text-primary-500" />
                  <span>All Over Tamil Nadu</span>
                </div>
              </motion.div>
              
              <motion.a
                href={`tel:${phoneNumber}`}
                className="flex items-center px-4 py-3 text-gray-900 hover:bg-primary-50 hover:text-primary-500 rounded-lg transition-all duration-300"
                whileHover={{ x: 10, backgroundColor: "rgba(59, 130, 246, 0.1)" }}
              >
                <Phone className="w-4 h-4 mr-2 text-primary-500" />
                +91 88382 40016
              </motion.a>
              
              <motion.a
                href={`https://wa.me/${phoneNumber.replace(/[^0-9]/g, '')}?text=Hi,%20I'm%20interested%20in%20solar%20installation`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center px-4 py-3 text-primary-600 hover:bg-primary-50 hover:text-primary-700 rounded-lg transition-all duration-300"
                whileHover={{ x: 10, backgroundColor: "rgba(59, 130, 246, 0.1)" }}
                onClick={() => setIsMenuOpen(false)}
              >
                <Phone className="w-4 h-4 mr-2" />
                Get Quote
              </motion.a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
