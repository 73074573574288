import { motion } from "framer-motion";
import { Sun, Battery, Leaf } from "lucide-react";
import { TypeAnimation } from 'react-type-animation';

export default function Hero() {
  const stats = [
    { 
      icon: (
        <motion.div
          animate={{ 
            rotate: 360,
          }}
          transition={{ 
            duration: 10,
            repeat: Infinity,
            ease: "linear"
          }}
        >
          <Sun />
        </motion.div>
      ), 
      value: "10+", 
      label: "Installations" 
    },
    { 
      icon: (
        <div className="relative">
          <motion.div
            animate={{ 
              scale: [1, 1.05, 1],
            }}
            transition={{ 
              duration: 1,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="relative z-10"
          >
            <Battery className="w-6 h-6" />
          </motion.div>
          <motion.div
            initial={{ width: "0%" }}
            animate={{ 
              width: ["0%", "100%"],
              backgroundColor: ["#22c55e44", "#22c55ecc"]
            }}
            transition={{ 
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="absolute left-[2px] top-[3px] h-[18px] rounded-sm z-0"
            style={{ width: "calc(100% - 8px)" }}
          />
        </div>
      ),
      value: "99%", 
      label: "Customer Satisfaction" 
    },
    { 
      icon: (
        <motion.div
          animate={{ 
            rotateY: 180,
          }}
          transition={{ 
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut"
          }}
        >
          <motion.div
            animate={{
              y: [0, -5, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            <Leaf />
          </motion.div>
        </motion.div>
      ),
      value: "10MW+", 
      label: "Power Generated" 
    },
  ];

  return (
    <section
      id="home"
      className="relative min-h-screen pt-24 md:pt-28 lg:pt-32 flex flex-col justify-center overflow-hidden bg-gradient-to-b from-black to-primary-950"
    >
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src="https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?auto=format&fit=crop&q=80"
          alt="Solar Panels"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/60"></div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-8"
          >
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white leading-tight">
              <TypeAnimation
                sequence={[
                  'Powering Tomorrow',
                  2000,
                  'Sustainable Energy',
                  2000,
                  'Clean Power Solutions',
                  2000,
                ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
              />
              <br className="hidden sm:block" />
              <span className="text-primary-400 mt-2 sm:mt-0 block sm:inline">With Solar Energy</span>
            </h1>
          </motion.div>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-lg sm:text-xl text-gray-300 mb-8 sm:mb-12 max-w-2xl mx-auto px-4 sm:px-0"
          >
            Bringing reliable solar solutions to Tamil Nadu with quality service and proven expertise.
          </motion.p>

          <div className="flex flex-col sm:flex-row gap-4 justify-center items-center px-4 sm:px-0">
            <motion.a
              href="#contact"
              className="w-full sm:w-auto inline-flex items-center justify-center px-6 sm:px-8 py-3 text-base font-medium text-white bg-primary-600 rounded-md shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-200"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get Started
            </motion.a>
            <motion.a
              href="#pricing-table"
              className="w-full sm:w-auto inline-flex items-center justify-center px-6 sm:px-8 py-3 text-base font-medium text-white bg-white/10 backdrop-blur-sm rounded-md hover:bg-white/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition-all duration-200"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              PM SUBSIDY SOLAR PROJECT
            </motion.a>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="mt-12 sm:mt-16"
          >
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8 max-w-3xl mx-auto">
              {stats.map((stat, index) => (
                <motion.div
                  key={stat.label}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 + 0.5 }}
                  className="flex flex-col items-center p-4 rounded-lg bg-white/5 backdrop-blur-sm"
                >
                  <div className="text-primary-400 mb-2 h-6">
                    {stat.icon}
                  </div>
                  <div className="text-2xl sm:text-3xl font-bold text-white mb-1">
                    {stat.value}
                  </div>
                  <div className="text-sm text-gray-300">
                    {stat.label}
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Dark gradient overlay at bottom */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black/50 to-transparent"
      />
    </section>
  );
}