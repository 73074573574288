import { Helmet } from 'react-helmet-async';

interface SeoProps {
  title?: string;
  description?: string;
  path?: string;
}

export default function Seo({ 
  title = 'Best Solar Panel Installation in Tamil Nadu | Residential & Commercial',
  description = 'Adithya Solar Systems - Leading solar solutions provider in Namakkal, Salem, Erode & Karur. Expert installation of solar panels, water heaters & UPS. Government subsidy assistance available. 99% customer satisfaction.',
  path = ''
}: SeoProps) {
  const siteUrl = 'https://markx.shop';
  const fullTitle = `Adithya Solar Systems - ${title}`;
  const url = `${siteUrl}${path}`;
  const imageUrl = 'https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?auto=format&fit=crop&q=80';

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="title" content={fullTitle} />
      <meta name="description" content={description} />
      <meta name="keywords" content="solar panels, solar installation, solar water heater, solar UPS, solar solutions Tamil Nadu, solar company Namakkal, solar panels Salem, solar installation Erode, government solar subsidy, commercial solar installation, residential solar panels" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="Adithya Solar Systems" />
      <meta property="og:locale" content="en_IN" />
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={fullTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
      
      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="author" content="Adithya Solar Systems" />
      <meta name="geo.region" content="IN-TN" />
      <meta name="geo.placename" content="Namakkal" />
      
      {/* Contact Information */}
      <meta name="contact" content="adithyasolarsystemsservices@gmail.com" />
      <meta name="contact:phone" content="+91 88382 40016" />
      
      <link rel="canonical" href={url} />
    </Helmet>
  );
}