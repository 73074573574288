import { motion } from 'framer-motion';
import { Sun, Battery, Wallet, Zap, ArrowRight, Clock, Calculator } from 'lucide-react';

// Table 1 Data - Solar Power Plant Price List
const priceListData = [
  { capacity: '1KW-1PH', price: 82500, subsidy: 30000 },
  { capacity: '2KW-1PH', price: 149500, subsidy: 60000 },
  { capacity: '3KW-1PH', price: 205000, subsidy: 78000 },
  { capacity: '4KW-1PH', price: 275000, subsidy: 78000 },
  { capacity: '5KW-3PH', price: 334500, subsidy: 78000 },
  { capacity: '6KW-3PH', price: 395500, subsidy: 78000 },
  { capacity: '7KW-3PH', price: 454000, subsidy: 78000 },
  { capacity: '8KW-3PH', price: 514200, subsidy: 78000 },
  { capacity: '9KW-3PH', price: 562900, subsidy: 78000 },
  { capacity: '10KW-3PH', price: 620000, subsidy: 78000 },
];

// Table 2 Data - Bill Details
const billDetailsData = [
  { capacity: '1KW', subsidy: 30000, currentBill: 'Rs.540-Rs.800', afterSolar: 'Rs.110', generation: '270-275' },
  { capacity: '2KW', subsidy: 60000, currentBill: 'Rs.2000-Rs.2500', afterSolar: 'Rs.220', generation: '550-650' },
  { capacity: '3KW', subsidy: 78000, currentBill: 'Rs.2500-Rs.5500', afterSolar: 'Rs.330', generation: '800-900' },
  { capacity: '4KW', subsidy: 78000, currentBill: 'Rs.5000-Rs.7500', afterSolar: 'Rs.440', generation: '1000-1300' },
  { capacity: '5KW', subsidy: 78000, currentBill: 'Rs.7500-Rs.11000', afterSolar: 'Rs.550', generation: '1350-1650' },
  { capacity: '6KW', subsidy: 78000, currentBill: 'Rs.11000-Rs.14500', afterSolar: 'Rs.660', generation: '1600-1900' },
  { capacity: '7KW', subsidy: 78000, currentBill: 'Rs.14500-Rs.17500', afterSolar: 'Rs.770', generation: '1900-2300' },
  { capacity: '8KW', subsidy: 78000, currentBill: 'Rs.17500-Rs.20500', afterSolar: 'Rs.880', generation: '2200-2600' },
  { capacity: '9KW', subsidy: 78000, currentBill: 'Rs.20500-Rs.23500', afterSolar: 'Rs.990', generation: '2400-2900' },
  { capacity: '10KW', subsidy: 78000, currentBill: 'Rs.23500-Rs.27000', afterSolar: 'Rs.1100', generation: '2700-3300' },
];

// Table 3 Data - Installation Details
const installationData = [
  { capacity: '1KW', subsidy: 30000, installationRate: 80000, finalRate: 50000, roi: '5.5 Years' },
  { capacity: '2KW', subsidy: 60000, installationRate: 140000, finalRate: 80000, roi: '4.5 Years' },
  { capacity: '3KW', subsidy: 78000, installationRate: 195000, finalRate: 117000, roi: '3-4 Years' },
  { capacity: '4KW', subsidy: 78000, installationRate: 245000, finalRate: 167000, roi: '3-4 Years' },
  { capacity: '5KW', subsidy: 78000, installationRate: 295000, finalRate: 217000, roi: '3-4 Years' },
  { capacity: '6KW', subsidy: 78000, installationRate: 340000, finalRate: 262000, roi: '3-4 Years' },
  { capacity: '7KW', subsidy: 78000, installationRate: 385000, finalRate: 307000, roi: '3-4 Years' },
  { capacity: '8KW', subsidy: 78000, installationRate: 430000, finalRate: 352000, roi: '2-3 Years' },
  { capacity: '9KW', subsidy: 78000, installationRate: 475000, finalRate: 397000, roi: '2-3 Years' },
  { capacity: '10KW', subsidy: 78000, installationRate: 520000, finalRate: 442000, roi: '2-3 Years' },
];

export default function PricingTable() {
  return (
    <section id="pricing-table" className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Table 1: Price List */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="mb-16"
        >
          <div className="text-center mb-8">
            <span className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-yellow-100 text-yellow-800 text-sm font-semibold mb-4">
              <Sun className="w-4 h-4" />
              PM - Surya Ghar : Muft Bijli Yojana
            </span>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Solar Power Plant Price LIST 2024</h2>
            <p className="text-xl text-gray-600">1KW to 10KW DCR Module Solar Power Plant Price LIST 2024</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                      <div className="flex items-center gap-2">
                        <Battery className="w-4 h-4" />
                        SOLAR POWER
                      </div>
                    </th>
                    <th className="px-6 py-3 text-center text-sm font-semibold text-gray-900">
                      <div className="flex items-center justify-center gap-2">
                        <Sun className="w-4 h-4" />
                        MONO CRYSTALLINE PANEL
                      </div>
                    </th>
                    <th className="px-6 py-3 text-right text-sm font-semibold text-gray-900">
                      <div className="flex items-center justify-end gap-2">
                        <Wallet className="w-4 h-4" />
                        GOVERNMENT SUBSIDY
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {priceListData.map((item, index) => (
                    <motion.tr 
                      key={item.capacity}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="hover:bg-gray-50 transition-colors duration-200"
                    >
                      <td className="px-6 py-4 text-sm text-gray-900">{item.capacity}</td>
                      <td className="px-6 py-4 text-sm text-center text-gray-900">₹ {item.price.toLocaleString()}</td>
                      <td className="px-6 py-4 text-sm text-right text-yellow-800 font-medium">{item.subsidy.toLocaleString()}</td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </motion.div>

        {/* Table 2: PM SUBSIDY PROJECT in Tamil Nadu */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="mb-16"
        >
          <div className="text-center mb-8">
            <span className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-yellow-100 text-yellow-800 text-sm font-semibold mb-4">
              <Zap className="w-4 h-4" />
              PM SUBSIDY PROJECT in Tamil Nadu
            </span>
            <p className="text-lg text-gray-600">Install Solar with Subsidy and save upto 90% of your Electricity Bill</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {['Recommended Solar Capacity', 'Government Subsidy', 'Your EB Bill Value (60 Days)', 'After Solar Installation', 'Average Generation'].map((header, index) => (
                      <th key={header} className="px-6 py-3 text-sm font-semibold text-gray-900">
                        <div className="flex items-center justify-center gap-2">
                          {index === 0 && <Battery className="w-4 h-4" />}
                          {index === 1 && <Wallet className="w-4 h-4" />}
                          {index === 2 && <Calculator className="w-4 h-4" />}
                          {index === 3 && <ArrowRight className="w-4 h-4" />}
                          {index === 4 && <Zap className="w-4 h-4" />}
                          {header}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {billDetailsData.map((item, index) => (
                    <motion.tr 
                      key={item.capacity}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="hover:bg-gray-50 transition-colors duration-200"
                    >
                      <td className="px-6 py-4 text-sm text-gray-900">{item.capacity}</td>
                      <td className="px-6 py-4 text-sm text-center text-yellow-800 font-medium">{item.subsidy.toLocaleString()}</td>
                      <td className="px-6 py-4 text-sm text-center text-gray-900">{item.currentBill}</td>
                      <td className="px-6 py-4 text-sm text-center text-gray-900">{item.afterSolar}</td>
                      <td className="px-6 py-4 text-sm text-right text-gray-900">{item.generation}</td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </motion.div>

        {/* Table 3: Installation Details */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="mb-16"
        >
          <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {['Recommended Solar Capacity', 'Government Subsidy', 'Installation Rate', 'Final Rate', 'ROI'].map((header, index) => (
                      <th key={header} className="px-6 py-3 text-sm font-semibold text-gray-900">
                        <div className="flex items-center justify-center gap-2">
                          {index === 0 && <Battery className="w-4 h-4" />}
                          {index === 1 && <Wallet className="w-4 h-4" />}
                          {index === 2 && <Calculator className="w-4 h-4" />}
                          {index === 3 && <ArrowRight className="w-4 h-4" />}
                          {index === 4 && <Clock className="w-4 h-4" />}
                          {header}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {installationData.map((item, index) => (
                    <motion.tr 
                      key={item.capacity}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="hover:bg-gray-50 transition-colors duration-200"
                    >
                      <td className="px-6 py-4 text-sm text-gray-900">{item.capacity}</td>
                      <td className="px-6 py-4 text-sm text-center text-yellow-800 font-medium">{item.subsidy.toLocaleString()}</td>
                      <td className="px-6 py-4 text-sm text-center text-gray-900">{item.installationRate.toLocaleString()}</td>
                      <td className="px-6 py-4 text-sm text-center text-gray-900">{item.finalRate.toLocaleString()}</td>
                      <td className="px-6 py-4 text-sm text-right text-gray-900">{item.roi}</td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </motion.div>

        {/* Footer Note */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center space-y-2"
        >
          <p className="text-sm font-semibold text-gray-900 mb-4">Solar Bidirectional energy meter / Net Meter Cost to be Paid by Customer</p>
          <div className="flex justify-center gap-8">
            <div className="px-6 py-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
              <p className="text-gray-900">3 Phase meter - ₹4,910</p>
            </div>
            <div className="px-6 py-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
              <p className="text-gray-900">1 Phase meter - ₹2,800</p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
